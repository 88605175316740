// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-doc-asa-js": () => import("./../../../src/pages/ApiDoc/asa.js" /* webpackChunkName: "component---src-pages-api-doc-asa-js" */),
  "component---src-pages-api-doc-ase-js": () => import("./../../../src/pages/ApiDoc/ase.js" /* webpackChunkName: "component---src-pages-api-doc-ase-js" */),
  "component---src-pages-api-doc-build-app-js": () => import("./../../../src/pages/ApiDoc/build-app.js" /* webpackChunkName: "component---src-pages-api-doc-build-app-js" */),
  "component---src-pages-api-doc-build-lib-js": () => import("./../../../src/pages/ApiDoc/build-lib.js" /* webpackChunkName: "component---src-pages-api-doc-build-lib-js" */),
  "component---src-pages-api-doc-db-2-js": () => import("./../../../src/pages/ApiDoc/db2.js" /* webpackChunkName: "component---src-pages-api-doc-db-2-js" */),
  "component---src-pages-api-doc-ibase-js": () => import("./../../../src/pages/ApiDoc/ibase.js" /* webpackChunkName: "component---src-pages-api-doc-ibase-js" */),
  "component---src-pages-api-doc-index-js": () => import("./../../../src/pages/ApiDoc/index.js" /* webpackChunkName: "component---src-pages-api-doc-index-js" */),
  "component---src-pages-api-doc-informix-js": () => import("./../../../src/pages/ApiDoc/informix.js" /* webpackChunkName: "component---src-pages-api-doc-informix-js" */),
  "component---src-pages-api-doc-mariadb-js": () => import("./../../../src/pages/ApiDoc/mariadb.js" /* webpackChunkName: "component---src-pages-api-doc-mariadb-js" */),
  "component---src-pages-api-doc-mssql-js": () => import("./../../../src/pages/ApiDoc/mssql.js" /* webpackChunkName: "component---src-pages-api-doc-mssql-js" */),
  "component---src-pages-api-doc-mssql-odbc-js": () => import("./../../../src/pages/ApiDoc/mssql_odbc.js" /* webpackChunkName: "component---src-pages-api-doc-mssql-odbc-js" */),
  "component---src-pages-api-doc-mssql-oledb-js": () => import("./../../../src/pages/ApiDoc/mssql_oledb.js" /* webpackChunkName: "component---src-pages-api-doc-mssql-oledb-js" */),
  "component---src-pages-api-doc-mysql-js": () => import("./../../../src/pages/ApiDoc/mysql.js" /* webpackChunkName: "component---src-pages-api-doc-mysql-js" */),
  "component---src-pages-api-doc-odbc-js": () => import("./../../../src/pages/ApiDoc/odbc.js" /* webpackChunkName: "component---src-pages-api-doc-odbc-js" */),
  "component---src-pages-api-doc-oracle-js": () => import("./../../../src/pages/ApiDoc/oracle.js" /* webpackChunkName: "component---src-pages-api-doc-oracle-js" */),
  "component---src-pages-api-doc-pgsql-js": () => import("./../../../src/pages/ApiDoc/pgsql.js" /* webpackChunkName: "component---src-pages-api-doc-pgsql-js" */),
  "component---src-pages-api-doc-servers-js": () => import("./../../../src/pages/ApiDoc/servers.js" /* webpackChunkName: "component---src-pages-api-doc-servers-js" */),
  "component---src-pages-api-doc-sqlbase-js": () => import("./../../../src/pages/ApiDoc/sqlbase.js" /* webpackChunkName: "component---src-pages-api-doc-sqlbase-js" */),
  "component---src-pages-api-doc-sqlite-js": () => import("./../../../src/pages/ApiDoc/sqlite.js" /* webpackChunkName: "component---src-pages-api-doc-sqlite-js" */),
  "component---src-pages-api-doc-template-index-js": () => import("./../../../src/pages/ApiDoc/template/index.js" /* webpackChunkName: "component---src-pages-api-doc-template-index-js" */),
  "component---src-pages-download-history-js": () => import("./../../../src/pages/Download/history.js" /* webpackChunkName: "component---src-pages-download-history-js" */),
  "component---src-pages-download-index-js": () => import("./../../../src/pages/Download/index.js" /* webpackChunkName: "component---src-pages-download-index-js" */),
  "component---src-pages-how-to-bind-js": () => import("./../../../src/pages/HowTo/bind.js" /* webpackChunkName: "component---src-pages-how-to-bind-js" */),
  "component---src-pages-how-to-blobs-js": () => import("./../../../src/pages/HowTo/blobs.js" /* webpackChunkName: "component---src-pages-how-to-blobs-js" */),
  "component---src-pages-how-to-commands-js": () => import("./../../../src/pages/HowTo/commands.js" /* webpackChunkName: "component---src-pages-how-to-commands-js" */),
  "component---src-pages-how-to-connect-js": () => import("./../../../src/pages/HowTo/connect.js" /* webpackChunkName: "component---src-pages-how-to-connect-js" */),
  "component---src-pages-how-to-datetime-js": () => import("./../../../src/pages/HowTo/datetime.js" /* webpackChunkName: "component---src-pages-how-to-datetime-js" */),
  "component---src-pages-how-to-errors-js": () => import("./../../../src/pages/HowTo/errors.js" /* webpackChunkName: "component---src-pages-how-to-errors-js" */),
  "component---src-pages-how-to-fetch-js": () => import("./../../../src/pages/HowTo/fetch.js" /* webpackChunkName: "component---src-pages-how-to-fetch-js" */),
  "component---src-pages-how-to-index-js": () => import("./../../../src/pages/HowTo/index.js" /* webpackChunkName: "component---src-pages-how-to-index-js" */),
  "component---src-pages-how-to-native-api-js": () => import("./../../../src/pages/HowTo/native_api.js" /* webpackChunkName: "component---src-pages-how-to-native-api-js" */),
  "component---src-pages-how-to-performance-js": () => import("./../../../src/pages/HowTo/performance.js" /* webpackChunkName: "component---src-pages-how-to-performance-js" */),
  "component---src-pages-how-to-procedures-js": () => import("./../../../src/pages/HowTo/procedures.js" /* webpackChunkName: "component---src-pages-how-to-procedures-js" */),
  "component---src-pages-how-to-query-example-js": () => import("./../../../src/pages/HowTo/query_example.js" /* webpackChunkName: "component---src-pages-how-to-query-example-js" */),
  "component---src-pages-how-to-return-param-js": () => import("./../../../src/pages/HowTo/return_param.js" /* webpackChunkName: "component---src-pages-how-to-return-param-js" */),
  "component---src-pages-how-to-transactions-js": () => import("./../../../src/pages/HowTo/transactions.js" /* webpackChunkName: "component---src-pages-how-to-transactions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-index-js": () => import("./../../../src/pages/Order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-order-license-js": () => import("./../../../src/pages/Order/license.js" /* webpackChunkName: "component---src-pages-order-license-js" */),
  "component---src-pages-order-references-js": () => import("./../../../src/pages/Order/references.js" /* webpackChunkName: "component---src-pages-order-references-js" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/Support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/Support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-privacy-js": () => import("./../../../src/pages/Support/privacy.js" /* webpackChunkName: "component---src-pages-support-privacy-js" */)
}

